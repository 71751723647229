<template>
<div class="footer">
    <hr>
    <div class="row">
        <div class="col-1"/>
        <div class="col-10 d-flex justify-content-end">
            <a id="datenschutz" href="/datenschutz"><h5>Datenschutz</h5></a>
            <a id="impressum" href="/impressum"><h5>Impressum</h5></a>
        </div>
        <div class="col-1"/>
    </div>
</div>
</template>

<style lang="scss">
    #impressum, #datenschutz{
        text-decoration: none;
        color: black;
    }

    #datenschutz{
        margin-right: 15px;
    }
</style>
