<template>
    <div class="row">
        <div class="col-1"/>
        <div class="col-10">
        <h1>Datenschutz:</h1>
        <h5>Datenschutzerklärung
        <br>
        <br>Verantwortlich im Sinne der Datenschutzgesetze: 
        <br>TSV Poppenhausen 1910 e.V.
        <br>Kontakt für diese Webseite: Alexander Ortloff
        <br>Rudolf-Werner-Straße 1A
        <br>97490 Poppenhausen 
        <br>
        <br><b>Datenschutz</b> 
        <br> Als Webseitenbetreiber nehmen wir den Schutz aller persönlichen Daten sehr ernst.
        <br> Uns ist der Schutz Ihrer persönlichen Daten wichtig und wir erklären Ihnen gerne, welche Daten wir wozu benötigen, wie lange wir die Daten speichern und was wir mit den uns anvertrauten personenbezogen Daten tun.
        <br> Was wir nicht mit Ihren persönlichen Daten machen:
        <br> 
        <br> • Unbefugt persönliche Daten an Dritte weiter geben. 
        <br> • Mehr als die notwendigen Daten von Ihnen erheben.
        <br> • Die Daten zu einem anderen als dem hier geschilderten Zweck verwenden. 
        <br>
        <br><b>Ihre Datenschutz-Rechte als Verbraucher</b>
        <br> 
        <br> Die Datenschutzgrundverordnung hat Ihre Rechte als Verbraucher deutlich gestärkt und gibt Ihnen folgende Möglichkeiten unseren Umgang mit Ihren personenbezogenen Daten zu kontrollieren.
        <br>
        <br> • Recht auf Auskunft 
        <br> Sie erhalten auf Wunsch Auskunft über die gesetzliche Grundlage, den praktischen Grund und die Dauer der Datenspeicherung. Über die Art und Kategorie der Daten, deren Verarbeitung und etwaige Weitergabe an berechtigte Dritte (Auftragsverarbeiter). 
        <br>
        <br> Alle personenbezogenen Informationen werden vertraulich und gemäß den gesetzlichen Vorschriften behandelt, wie in dieser Datenschutzerklärung erläutert.
        <br>
        <br>Unserer Webseite kann selbstverständlich genutzt werden, ohne dass Sie persönliche Daten angeben. Wenn jedoch zu irgendeinem Zeitpunkt persönliche Daten wie z.B. Name, Adresse oder E-Mail abgefragt werden, wird dies auf freiwilliger Basis geschehen. Niemals werden von uns erhobene Daten ohne Ihre spezielle Genehmigung an Dritte weitergegeben. 
        <br>Datenübertragung im Internet, wie zum Beispiel über E-Mail, kann immer Sicherheitslücken aufweisen. Der komplette Schutz der Daten ist im Internet nicht möglich. 
        <br>
        <br><b>Datenschutzerklärung Google Analytics </b>
        <br>Unsere Website nutzt den Analysedienst Google Analytics, betrieben von Google Inc. 1600 Amphitheatre Parkway Mountain View, CA 94043, USA. Google Analytics nutzt “Cookies”, das sind kleine Textdateien, die in Ihrem Browser gespeichert werden und die es ermöglichen, die Nutzung unserer Website durch die Besucher zu analysieren. Von den Cookies werden Daten über Ihre Nutzung unserer Webseite gesammelt, die normalerweise an einen Google-Server in den USA übertragen und gespeichert werden. 
        <br>Wenn die IP-Anonymisierung auf unserer Webseite aktiviert wurde, wird Ihre IP-Adresse von Google innerhalb der Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vorab gekürzt. In seltenen Ausnahmefällen kann die komplette IP-Adresse an einen Google Server in den USA übertragen werden, dann wird diese dort gekürzt. Google nutzt diese Daten in unserem Auftrag, um die Nutzung unserer Website auszuwerten, um Berichte über die Webseitenaktivitäten zu erstellen sowie um weitere Dienstleistungen anzubieten, die mit der Webseitennutzung und Internetnutzung zusammenhängen. Die von Google Analytics erfassten IP-Adressen werden nicht mit anderen Daten von Google korreliert. 
        <br> Die Speicherung von Cookies kann durch eine spezielle Einstellung in Ihrem Browser verweigert werden. In diesem Fall ist jedoch die Funktionalität unserer Webseite im vollen Umfang nicht gewährleistet. Zusätzlich steht ihnen ein Browser-Plugin zu Verfügung, mit dem Sie die Sammlung der auf Ihre Nutzung der Website bezogenen erzeugten Daten und IP-Adressen durch Google verhindern können. Mehr Informationen dazu finden Sie hier: http://tools.google.com/dlpage/gaoptout?hl=de 
        <br>
        <br><b>Datenschutzerklärung Google +1 </b>
        <br>Auf unsere Seiten werden Funktionen von Google +1 genutzt, angeboten von Google Inc. 1600 Amphitheatre Parkway Mountain View, CA 94043, USA. 
        <br>Sammlung und Übertragung von Daten: Sie können die Google +1-Schaltfläche nutzen, um Informationen weltweit zu veröffentlichen. Die Google +1-Schaltfläche präsentiert Ihnen und anderen Nutzern individuell abgestimmte Inhalte von Google und deren Partnern. Google sammelt Daten über die Informationen, die Sie für einen +1 Inhalt gegeben haben, sowie Daten über die Webseite, die Sie angesehen haben, während Sie auf +1 geklickt haben. Ihre +1 Daten können zusammen mit Ihrem Profilnamen und Ihrem Foto in unterschiedlichen Google-Diensten, wie Suchergebnissen, Ihrem Google-Profil, sowie auf Webseiten und Werbeanzeigen im Internet eingeblendet werden. Informationen über Ihre +1-Aktivitäten werden von Google aufgezeichnet, um die von Ihnen genutzten Google-Dienste zu verbessern. Um Google +1 Schaltflächen nutzen zu können, müssen Sie über ein öffentliches Google-Profil verfügen, in dem mindestens der Name des Profils enthalten sein muss. Alle Google-Dienste verwenden diesen Profilnamen. Manchmal kann dieser Name auch einen anderen Namen ersetzen, den Sie verwendet haben, wenn Inhalte über Ihr Google-Konto mit anderen Nutzern geteilt wurden. Nutzern, die Ihre E-Mail-Adresse kennen oder über andere identifizierende Daten von Ihnen verfügen, kann die Identität Ihres Google-Profils angezeigt werden. <br>Nutzung der gesammelten Daten: Zusätzlich zu der bereits beschriebenen Nutzung unterliegen die von Ihnen bereitgestellten Daten den geltenden Google-Datenschutzbestimmungen. Google kann allgemeine Statistiken über die +1-Aktivitäten der Nutzer veröffentlichen, oder diese an Nutzer und Partner, wie Publisher, Inserenten oder Partner- Webseiten, weitergeben. 
        <br>Auskunft, Löschung, Sperrung 
        <br>Zu jedem Zeitpunkt können Sie sich über die personenbezogenen Daten, deren Herkunft und Empfänger und den Nutzen der Datenverarbeitung informieren und unentgeltlich eine Korrektur, Sperrung oder Löschung dieser Daten verlangen. Bitte nutzen Sie dafür die im Impressum angegebenen Kontaktwege. Für weitere Fragen zum Thema stehen wir Ihnen ebenfalls jederzeit zur Verfügung. 
        <br>
        <br><b>Server-Log-Files </b>
        <br>Der Seiten-Provider erhebt und speichert automatisch Daten in Server-Log Files, die von Ihrem Browser an uns übermittelt werden. Diese Daten enthalten: 
        <br>– Browsertyp/ Browserversion 
        <br>– Betriebssystem des Rechners 
        <br>– Referrer URL 
        <br>– Hostname des zugreifenden Rechners 
        <br>– Uhrzeit der Serveranfrage 
        <br>Diese Daten sind nicht personenbezogen. Es erfolgt keine Zusammenführung dieser Daten mit anderen Datenquellen. Wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden behalten wir uns das Recht vor, diese Daten nachträglich zu überprüfen. 
        <br>
        <br><b>Cookies </b>
        <br>Viele Internetseiten verwenden Cookies. Cookies sind unschädlich für Ihren Rechner und virenfrei. Sie dienen dazu, Internet-Angebote für die Besucher einer Webseite freundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Computer abgelegt werden und die Ihr Browser verwendet. 
        <br>Wir verwenden in der Regel so genannte „Session-Cookies“. Diese werden nach Verlassen unserer Seite automatisch gelöscht. Andere Cookies bleiben auf Ihrem Computer gespeichert, bis Sie diese löschen. Diese Cookies helfen dabei, Ihren Rechner beim nächsten Besuch wiederzuerkennen. 
        <br>Über die Browsereinstellungen können sie festlegen, dass Sie über neue Cookies informiert werden und Cookies jeweils annehmen müssen. Ebenso können Sie die Annahme von Cookies für bestimmte Fälle oder generell ausschließen oder das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Werden Cookies desaktiviert, kann die Funktionalität unserer Website eingeschränkt sein. 
        <br>
        <br><b>Kontaktformular </b>
        <br>Kontaktdaten, die uns über unser Kontaktformular erreichen, werden inklusive des Inhalts der Anfrage für Bearbeitungszwecke und für mögliche Anschlussfragen gespeichert. Diese Daten werden ohne Ihre spezifische Zustimmung nicht weitergegeben. 
        <br>Änderung der Datenschutzbestimmungen 
        <br>Unsere Datenschutzerklärung können in unregelmäßigen Abständen angepasst werden, damit sie den aktuellen rechtlichen Anforderungen entsprechen oder um Änderungen unserer Dienstleistungen umzusetzen, z. B. bei der Einfügung neuer Angebote. Für Ihren nächsten Besuch gilt dann automatisch die neue Datenschutzerklärung. 
        <br>
        <br><b>Kontakt zum Datenschutzmitarbeiter </b>
        <br>Für Fragen zum Datenschutz schicken Sie uns bitte eine Nachricht an tt.poppenhausen@outlook.de mit dem Betreff „Datenschutz“. 
        <br>Diese Widerrufsbelehrung Seite wurde bei datenschutzerklärungmuster.de erstellt.
        <br>
        <br> <b>Aktualität der Datenschutzerklärung</b>
        <br> Die Datenschutzerklärung hat den Stand vom 01.08.2022 und kann jederzeit aktuellen Änderungen in der Rechtsprechung oder bei technischen Entwicklungen angepasst werden.
        <br>
        <br> <b>Weiterführende Informationen:</b>
        <br>
        <a href="https://www.bmj.de/DE/Themen/FokusThemen/DSGVO/DSVGO_node.html">Bundesministerium der Justiz: Datenschutz-Grundverordnung</a>
        <br>
       <a href="https://www.lda.bayern.de/de/thema_vereine.html">Bayrisches Landesamt für Datenschutzaufsicht- Praxisratgeber für Vereine</a>
       <br>
        <a href="https://www.handelsregister.de/rp_web/erweitertesuche.xhtml">Vereinsregister Bayern Amtsgericht Schweinfurt VR-Nr 237 </a>
        <br>
        </h5>
        </div>
        <div class="col-1"/>
    </div>
</template>

<style lang="scss">
</style>