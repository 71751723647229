<template>
    <div>
        <br>
        <hr>
        <div class="row">
            <div class="col-1"/>
            <div class="col-10 d-flex justify-content-between">
                <h5 class="align-self-center">Mit freundlicher Unterstützung des TSV Poppenhausen 1910 e.V. </h5>
                
                <img class="align-self-center" src="../assets/Bilder/TSVWappenkd.jpg">
                <a class="align-self-center" href="https://tsv-poppenhausen-ufr.de"><h5>Webseite: TSV Poppenhausen 1910 e.V. </h5></a>
            </div>
            <div class="col-1"/>
        </div>
    </div>
</template>   
